import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import DatePicker from '../DatePicker/DatePicker';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	// width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

export default function BasicModal() {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<div>
			<Button onClick={handleOpen}>BOOK NOW</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<Typography
						sx={{
							verticalAlign: 'middle',
							display: 'inline-flex',
						}}
						id='modal-modal-title'
						variant='h6'
						component='h2'>
						<CalendarTodayIcon />
						Book Trip
					</Typography>
					<Typography id='modal-modal-description' sx={{ mt: 2 }}>
						Pick your dates from the calendar below
					</Typography>
					<DatePicker />
				</Box>
			</Modal>
		</div>
	);
}
